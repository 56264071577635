import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { buildFilters } from 'config/filters.js';
import { Form } from 'react-bootstrap';
import SearchTags from 'components/shared/SearchTags';

const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.5rem;
	padding-right: 1rem;
	line-height: 1;
`;

const Tag = styled(Link)`
	display: block;
	color: black;
	text-decoration: none !important;
	font-size: 0.8rem;

	white-space: nowrap;
	border-radius: 999px;
	padding: 0.5rem 0.75rem;
	background: var(--lightest);
	text-transform: uppercase;
	transition: 0.1s;
	text-shadow: none;
	cursor: pointer;

	&:hover,
	&:focus {
		background: var(--bs-primary);
		color: white;
	}
`;

const ColorTag = styled(Tag)`
	background: ${({ $color }) => `var(--${$color})`};
	text-shadow: 0 1px 2px #0002;
	color: ${({ $color }) => ($color !== 'White' ? 'white' : 'black')};
	text-shadow: ${({ $color }) => ($color !== 'White' ? 'inherit' : 'none')};
	box-shadow: inset 0 -2px 0 #0001;

	&:hover,
	&:focus {
		background: var(--bs-dark);
	}
`;

const CheckWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const ColorSwatch = styled.div`
	width: 1rem;
	height: 1rem;
	background: ${({ $color }) => `var(--${$color})`};
	border-radius: 50%;
	box-shadow: inset 0 -2px 0 #0001;
`;

const CustomSearchTags = styled(SearchTags)`
	.form-control {
		box-shadow: none;
		background: var(--lightest);
	}
`;

const Tags = ({ name, tags, isEditing, handleChange, formResponse, setFormResponse }) => {
	// State
	const [filters, setFilters] = useState([]);

	// Get all tags from data list
	const allTags = filters?.find(filter => filter.name === name)?.values;

	// Effects
	useEffect(() => {
		const getFilters = async () => {
			const data = await buildFilters();
			setFilters(data);
		};
		getFilters();
	}, []);

	return (
		<>
			{!isEditing ? (
				tags?.length > 0 ? (
					<TagContainer>
						{tags?.map(tag =>
							name !== 'color_scheme' ? (
								<Tag key={tag} to={`/examples?${name}=${tag}`}>
									{tag}
								</Tag>
							) : (
								<ColorTag key={tag} $color={tag} to={`/examples?${name}=${tag}`}>
									{tag}
								</ColorTag>
							)
						)}
					</TagContainer>
				) : (
					<p className='m-0'>{name !== 'visibility' ? 'None' : 'Everyone'}</p>
				)
			) : name === 'search_tags' ? (
				<CustomSearchTags formResponse={formResponse} setFormResponse={setFormResponse} handleChange={handleChange} embed={true} />
			) : (
				allTags?.map(tag => (
					<CheckWrapper key={tag}>
						<Form.Check className='m-0' type={'checkbox'} id={tag} label={tag} value={tag} name={name} checked={tags?.includes(tag) ? true : false} onChange={e => handleChange(e)} />
						{name === 'color_scheme' && <ColorSwatch $color={tag} />}
					</CheckWrapper>
				))
			)}
		</>
	);
};

export default Tags;
