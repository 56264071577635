export default function LoadingOverlay({ small }) {
	const overlayStyle = {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 999
	};
	const spinnerStyle = small
		? {
				width: '2rem',
				height: '2rem'
		  }
		: {
				width: '5rem',
				height: '5rem'
		  };

	return (
		<div className='loading-overlay' style={overlayStyle}>
			<div className='spinner-border text-primary' style={spinnerStyle}>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	);
}
