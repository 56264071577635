import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { Accordion, AccordionCollapse, Button, Form, useAccordionButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import ExamplesContainer from './Main/ExamplesContainer';
import Filter from './Sidebar/Filter';
import Sort from './Sidebar/Sort';
import Search from './Sidebar/Search';
import Fuse from 'fuse.js';
import { useLogin } from 'utils/UserContext';
import { useMedia } from 'react-use';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { useForm } from 'react-hook-form';
import { transformURLParamsToObject } from 'utils/Tidy';

const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 300px auto;
	gap: 1.5rem;
	align-items: start;

	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;

const Heading = styled.h5`
	background: white;
	margin: 0;
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;

	i {
		font-size: 1rem;
	}
`;

const Sidebar = styled(Form)`
	display: grid;
	gap: 1.5rem;
	position: sticky;
	top: 1.5rem;
	max-height: calc(100vh - 3rem);
	overflow-y: auto;
	border-radius: 0.5rem;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const MobileSidebar = styled(Form)`
	display: grid;
	gap: 1rem;
	margin-top: 1rem;
`;

const Panel = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
`;

const ExampleCount = styled.div`
	text-align: center;
	margin: 0.75rem;
`;

const ClearButton = styled(Button)`
	&.btn {
		width: 100%;
		border-radius: 0 0 0.5rem 0.5rem;
		height: 3rem;
		padding: 0.5rem 0.75rem !important;
		margin: 0 !important;

		&.disabled {
			background: var(--bs-gray-500);
			color: var(--bs-gray-100);
			border: 0;
		}
	}
`;

const CustomAccordion = styled(Accordion)`
	justify-self: stretch;
`;

const Toggle = styled.button`
	all: unset;
	width: 100%;
	text-align: center;
`;

const ToggleComponent = ({ eventKey, children, ...props }) => {
	// Hooks
	const handleToggleAccordion = useAccordionButton(eventKey, () => null);

	return (
		<Toggle onClick={handleToggleAccordion} {...props}>
			{children}
		</Toggle>
	);
};

const Examples = ({ mainRef }) => {
	// Hooks
	const { user } = useLogin();
	const exclude = !user.isWebsiteTeam ? '?exclude=Internal%20Only' : '';
	const { data: examples, mutate } = useSWR(`/api/v1/examples/all${exclude}`);
	const allExampleIds = examples?.map(example => example._id);

	// URL Params
	const [searchParams, setSearchParams] = useSearchParams();
	const searchParamsExist = searchParams.size > 0;

	// Component state
	const [renderedExamples, setRenderedExamples] = useState(examples ?? []);
	const isMobile = useMedia('(max-width: 768px)');

	// Form state
	const defaultValues = { search: '', sort: 'date_published', show_favorites: false, property_type: false, website_purpose: false, website_features: false, third_party_features: false, color_scheme: false, location_state: false, company_name: false };
	const { handleSubmit, register, reset } = useForm({ defaultValues, values: transformURLParamsToObject(searchParams) });

	// Search
	const searchExamples = () => {
		const search = searchParams.get('search');
		if (!search) return allExampleIds;

		// Fuse config
		const fuseOptions = { keys: ['url', 'company_name', 'location_state', 'website_purpose', 'property_type', 'website_features', 'third_party_features', 'color_scheme', 'search_tags'], ignoreLocation: true, threshold: 0.1, minMatchCharLength: 3, includeScore: true, findAllMatches: true, ignoreFieldNorm: true };
		const fuse = new Fuse(examples, fuseOptions);
		return fuse.search(search).map(result => result.item._id);
	};

	// Favorites
	const favoriteExamples = () => {
		const showFavorites = searchParams.get('show_favorites');
		if (!showFavorites) return allExampleIds;
		return examples.filter(example => example.favorited_by.includes(user._id)).map(example => example._id);
	};

	// Filter
	const filterExamples = () => {
		const validFilterNames = ['property_type', 'website_purpose', 'website_features', 'third_party_features', 'color_scheme', 'location_state', 'company_name', 'product_tier', 'website_template'];
		const validFilters = [...searchParams.keys()].filter(key => validFilterNames.includes(key));
		if (validFilters.length === 0) return allExampleIds;
		return examples.filter(example => validFilters.every(key => example[key].includes(searchParams.get(key)))).map(example => example._id);
	};

	// Sort
	const sortExamples = () => {
		const sort = searchParams.get('sort');
		if (sort === 'date_published' || !sort) {
			setRenderedExamples(examples => [...examples].sort((a, b) => (new Date(a.first_published_date) > new Date(b.first_published_date) ? -1 : 1)));
		}
		if (sort === 'date_added') {
			setRenderedExamples(examples => [...examples].sort((a, b) => (new Date(a.date_added) > new Date(b.date_added) ? -1 : 1)));
		}
		if (sort === 'url') {
			setRenderedExamples(examples => [...examples].sort((a, b) => (a.url < b.url ? -1 : 1)));
		}
		if (sort === 'company_name') {
			setRenderedExamples(examples => [...examples].sort((a, b) => (a.company_name < b.company_name ? -1 : 1)));
		}
	};

	// Effects
	useEffect(() => {
		if (examples) setRenderedExamples(examples);
	}, [examples]);

	useEffect(() => {
		if (searchParams.size > 0 && examples) {
			const searchIds = searchExamples();
			const favoriteIds = favoriteExamples();
			const filteredIds = filterExamples();
			const results = examples.filter(example => searchIds.includes(example._id) && favoriteIds.includes(example._id) && filteredIds.includes(example._id));
			setRenderedExamples(results);
			sortExamples();

			// Scroll back to top of container
			mainRef.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, examples]);

	// Handlers
	const handleOnSubmit = data => {
		Object.keys(data).forEach(key => (!data[key] || data[key].length === 0) && delete data[key]);
		const newParams = new URLSearchParams(data).toString();
		setSearchParams(newParams);
	};

	const handleClearFilters = () => {
		setSearchParams();
		reset(defaultValues);
		setRenderedExamples(examples);

		// Scroll back to top of container
		mainRef.current.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<>
			<Helmet>
				<title>Latest Examples | Live AppFolio Website Examples</title>
			</Helmet>
			<PageHeader heading='Latest Examples' subheading='Sort and filter through the most recent AppFolio website examples.' />
			{examples ? (
				<Container>
					{!isMobile ? (
						<Sidebar onSubmit={handleSubmit(handleOnSubmit)}>
							<Panel>
								<ExampleCount>
									Showing <strong>{renderedExamples?.length}</strong> examples
								</ExampleCount>
								<ClearButton className={!searchParamsExist && 'disabled'} disabled={!searchParamsExist} onClick={() => handleClearFilters()}>
									Clear Filters <i className='fas fa-broom'></i>
								</ClearButton>
							</Panel>
							<Search register={register} submit={handleSubmit(handleOnSubmit)} />
							<Sort register={register} submit={handleSubmit(handleOnSubmit)} />
							<Filter register={register} submit={handleSubmit(handleOnSubmit)} />
						</Sidebar>
					) : (
						<CustomAccordion flush>
							<ToggleComponent eventKey='Sidebar'>
								<Heading>
									Search, Sort & Filter <i className='fas fa-angle-down text-secondary ms-1'></i>
								</Heading>
							</ToggleComponent>
							<AccordionCollapse eventKey='Sidebar'>
								<MobileSidebar onSubmit={handleSubmit(handleOnSubmit)}>
									{searchParamsExist && (
										<Panel>
											<ExampleCount>
												<strong>{renderedExamples?.length}</strong> examples found
											</ExampleCount>
											<ClearButton>
												Clear Filters <i className='fas fa-broom'></i>
											</ClearButton>
										</Panel>
									)}
									<Search register={register} submit={handleSubmit(handleOnSubmit)} />
									<Sort register={register} submit={handleSubmit(handleOnSubmit)} />
									<Filter register={register} submit={handleSubmit(handleOnSubmit)} />
								</MobileSidebar>
							</AccordionCollapse>
						</CustomAccordion>
					)}
					<ExamplesContainer allExamples={examples} user={user} mutate={mutate} renderedExamples={renderedExamples} setRenderedExamples={setRenderedExamples} />
				</Container>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Examples;
