const PageHeader = ({ heading, subheading, ...rest }) => {
	return (
		<div className={`${rest?.className?.includes('mb-md') ? rest.className : 'mb-md-5'}`}>
			<h1>{heading}</h1>
			<p className='text-secondary'>{subheading}</p>
		</div>
	);
};

export default PageHeader;
