import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import useSWR from 'swr';
import ExampleCard from 'components/Examples/Main/ExampleCard';
import { Helmet } from 'react-helmet';
import LoadingOverlay from 'components/shared/LoadingOverlay';

const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
`;

const ExampleContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-auto-rows: max-content;
	gap: 1rem;
	margin-bottom: 1.25rem;

	@media (max-width: 768px) {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		gap: 1rem;
	}
`;

const NoResults = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	i {
		font-size: 5rem;
		opacity: 0.25;
	}
`;

const Review = () => {
	// Component state
	const [isLoading, setIsLoading] = useState(true);

	// Fetch examples
	const { data: examples } = useSWR('/api/v1/examples/unapproved');

	// Remove loading
	useEffect(() => {
		if (examples) setIsLoading(false);
	}, [examples]);

	return (
		<>
			<Helmet>
				<title>Review Examples | Live AppFolio Website Examples</title>
			</Helmet>
			<PageHeader heading='Review Examples' subheading='Review examples before approving them for customer demonstration.' />
			<Container>
				{!isLoading ? (
					examples?.length > 0 ? (
						<ExampleContainer>
							{examples.map(example => (
								<ExampleCard key={example._id} site={example} />
							))}
						</ExampleContainer>
					) : (
						<NoResults>
							<i className='fas fa-laugh-beam'></i>
							<h5 className='my-3 text-center'>Nothing to review.</h5>
						</NoResults>
					)
				) : (
					<LoadingOverlay />
				)}
			</Container>
		</>
	);
};

export default Review;
