import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Form, Row, Col } from 'react-bootstrap';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useLogin } from 'utils/UserContext';

const CustomTypeahead = styled(Typeahead)`
	.dropdown-menu {
		padding: 0;
		margin: 0.5rem 0 0;
	}

	.dropdown-item.active .text-secondary {
		color: white !important;
	}
`;

const MenuItem = styled.div`
	line-height: 1.25;
	margin: 0.25rem 0;
`;

const Find = () => {
	// Hooks
	const navigate = useNavigate();
	const { user } = useLogin();

	// Use SWR Hook
	const exclude = !user.isWebsiteTeam ? '?exclude=Internal%20Only' : '';
	const { data } = useSWR(`/api/v1/examples/find/all${exclude}`);
	const options = data ? data : [];

	const goToSite = e => {
		const siteId = e[0].site_id;
		navigate({ pathname: `/site/${siteId}` });
	};

	return (
		<>
			<Helmet>
				<title>Find a Site | Live AppFolio Website Examples</title>
			</Helmet>
			<PageHeader heading={'Find a Site'} subheading={'Find the details of a specific site by URL.'} />
			{options.length > 0 ? (
				<Row>
					<Col xl={8}>
						<Form>
							<Form.Group>
								<CustomTypeahead
									className='mb-2'
									id='site-search'
									labelKey='url'
									filterBy={['company_name']}
									options={options}
									renderMenuItemChildren={(option, props, i) => (
										<MenuItem>
											<Highlighter search={props.text}>{option.url}</Highlighter>
											<div>
												<small className='text-secondary'>{option.company_name}</small>
											</div>
										</MenuItem>
									)}
									placeholder='URL or company name...'
									minLength={2}
									onChange={e => goToSite(e)}
								/>
								<Form.Text className='text-secondary'>Search for a site by URL or company name.</Form.Text>
							</Form.Group>
						</Form>
					</Col>
				</Row>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Find;
