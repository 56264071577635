import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled(Button)`
	display: grid;
	grid-template-columns: repeat(2, min-content);
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	pointer-events: none;
`;

const Icon = styled.span`
	opacity: 0.6;
`;

const LoadingButton = props => {
	return (
		<Component {...props}>
			Loading <Icon className='spinner-border spinner-border-sm'></Icon>
		</Component>
	);
};

export default LoadingButton;
