import { FormSelect } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	overflow: hidden;
`;

const SortInput = styled(FormSelect)`
	border-radius: 0;
`;

const Heading = styled.h5`
	background: var(--bs-dark);
	color: white;
	padding: 0.75rem 1rem;
	margin: 0;

	i {
		font-size: 1rem;
	}
`;

const Sort = ({ register, submit }) => {
	return (
		<Component>
			<Heading>
				<i className='fas fa-sort text-secondary me-1'></i> Sort
			</Heading>
			<SortInput {...register('sort', { onChange: () => submit() })}>
				<option value='date_published'>Date Published</option>
				<option value='date_added'>Date Added</option>
				<option value='url'>URL</option>
				<option value='company_name'>Company name</option>
			</SortInput>
		</Component>
	);
};

export default Sort;
