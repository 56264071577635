import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';

const Component = styled.button`
	all: unset;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	font-size: 1.5rem;
	color: white;
	padding: 0.5rem 0.75rem;
	text-shadow: 0 2px 5px #0003;
	transition: 0.2s;

	&:hover,
	&:focus-visible {
		color: var(--bs-yellow);
	}

	&.favorite {
		color: var(--bs-yellow);
	}
`;

const FavoriteButton = ({ site, user, mutate, renderedExamples, setRenderedExamples }) => {
	// State
	const { _id, site_id, favorited_by } = { ...site };
	const isFavorited = favorited_by?.includes(user._id);

	// Hooks
	const { setMessage } = useMessage();

	const handleToggleFavorite = async () => {
		try {
			const payload = !favorited_by.includes(user._id) ? [...favorited_by, user._id] : favorited_by.filter(id => id !== user._id);
			const optimisticData = renderedExamples.map(example => (example._id === _id ? { ...example, favorited_by: payload } : example));
			setRenderedExamples(optimisticData);

			await fetch(`/api/v1/examples/${site_id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ favorited_by: payload }) });
			await mutate();
		} catch (error) {
			setMessage({ text: error.message, variant: 'danger' });
		}
	};

	return (
		<Component className={isFavorited ? 'favorite' : ''} onClick={() => handleToggleFavorite()}>
			{!isFavorited ? <i className='far fa-star'></i> : <i className='fas fa-star'></i>}
		</Component>
	);
};

export default FavoriteButton;
