import styled from 'styled-components';

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000a;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: 0.1s;
	display: grid;
	place-items: center;
	text-shadow: 0 1px 2px #0009;

	div {
		color: white;
		text-align: center;

		p {
			font-size: 1.25rem;
			margin-top: 1rem;
		}

		i {
			font-size: 3rem;
		}
	}
`;

const Device = styled.a`
	text-decoration: none !important;
	width: 100%;
	border-radius: 0.5rem;
	overflow: hidden;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	position: relative;

	&:hover ${Overlay}, &:focus ${Overlay} {
		opacity: 1;
		visibility: visible;
	}
`;

const Toolbar = styled.div`
	background: #222;
	display: flex;
	align-items: center;
	height: 2.5rem;
`;

const Icons = styled.div`
	display: flex;
	padding-left: 0.25rem;
`;

const Icon = styled.div`
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	margin-left: 0.5rem;
	font-weight: bold;
	font-size: 0.6rem;
	position: relative;
	color: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	background: ${({ $color }) => `var(--bs-${$color})`};
`;

const Content = styled.div`
	background: white;
	aspect-ratio: 3/4;
	position: relative;

	img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		object-fit: cover;
	}
`;

const Browser = ({ url, image }) => {
	return (
		<Device href={`https://${url}`} target='_blank' rel='noopener noreferrer'>
			<Overlay>
				<div>
					<i className='fas fa-external-link-alt'></i>
					<p>View website</p>
				</div>
			</Overlay>
			<Toolbar>
				<Icons>
					<Icon $color='danger'>
						<i className='fas fa-times'></i>
					</Icon>
					<Icon $color='warning'>
						<i className='fas fa-minus'></i>
					</Icon>
					<Icon $color='success'>
						<i className='fas fa-expand-alt'></i>
					</Icon>
				</Icons>
			</Toolbar>
			<Content>
				<img src={image} alt='Desktop Screenshot' width='100%' />
			</Content>
		</Device>
	);
};

export default Browser;
