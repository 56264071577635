import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Category from './Category';
import { buildFilters } from 'config/filters.js';
import { FormCheck } from 'react-bootstrap';

const Wrapper = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	overflow: auto;
`;

const Heading = styled.h5`
	background: var(--bs-dark);
	color: white;
	padding: 0.75rem 1rem;
	position: sticky;
	top: 0;
	z-index: 10;
	margin: 0;

	i {
		font-size: 1rem;
	}
`;

const Content = styled.div`
	padding: 0.75rem 1rem;
	position: relative;
`;

const Filter = ({ register, submit }) => {
	// State
	const [filters, setFilters] = useState([]);

	// Effects
	useEffect(() => {
		const getFilters = async () => {
			const data = await buildFilters();
			setFilters(data);
		};
		getFilters();
	}, []);

	return (
		<Wrapper>
			<Heading>
				<i className='fas fa-filter text-secondary me-1'></i> Filter
			</Heading>
			<Content>
				<FormCheck type='switch' id='show-favorites' label='Show My Favorites' {...register('show_favorites', { onChange: () => submit() })} />
				{filters
					.filter(category => category.show_in_sidebar)
					.map(category => (
						<Category key={category.name} category={category} register={register} submit={submit} />
					))}
			</Content>
		</Wrapper>
	);
};

export default Filter;
