import styled from 'styled-components';
import FavoriteButton from './FavoriteButton';
import { Link } from 'react-router-dom';

const Card = styled.div`
	position: relative;
	overflow: hidden;
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	aspect-ratio: 4/3;
	transition: 0.1s;
`;

const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
`;

const Content = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(transparent, black);
	color: white;
	text-shadow: 0 2px 5px #0003;
	padding: 2rem 0 0.75rem 1rem;
	z-index: 1;
	display: grid;
	align-items: end;
	gap: 0.25rem;
`;

const Url = styled.h5`
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Name = styled.p`
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TagRow = styled.div`
	display: grid;
	grid-template-columns: 1.5rem 1fr;
	align-items: center;
	height: 1.5rem;
	gap: 0.5rem;
`;

const TagContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	overflow-x: auto;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	line-height: 1;
	padding-right: 0.5rem;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const Tag = styled(Link)`
	font-size: 0.7rem;
	border-radius: 999px;
	padding: 0.25rem 0.5rem;
	background: ${({ $color }) => `var(--${$color})`};
	color: ${({ $color }) => ($color !== 'White' ? 'white' : 'black')};
	text-shadow: ${({ $color }) => ($color !== 'White' ? '0 1px 2px #0009' : 'none')};
	text-transform: uppercase;
	transition: 0.1s;
	white-space: nowrap;

	&:hover,
	&:focus {
		background: white;
		color: var(--bs-dark);
		text-shadow: none;
	}
`;

const Banner = styled.div`
	position: absolute;
	top: 1.5rem;
	left: -2.5rem;
	width: 10rem;
	color: white;
	z-index: 1;
	font-size: 0.8rem;
	padding: 0.5rem;
	background: var(--bs-primary);
	transform: rotate(-40deg);
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	i {
		opacity: 0.6;
	}
`;

const ExampleCard = ({ site, user, allExamples, mutate, renderedExamples, setRenderedExamples }) => {
	// Props
	const { site_id, approved, thumbnail_url, url, company_name, website_purpose, property_type, color_scheme, website_features, third_party_features, visibility } = { ...site };

	// Tags
	const website_purpose_tags = website_purpose.map(tag => ({ tag, type: 'website_purpose' }));
	const property_type_tags = property_type.map(tag => ({ tag, type: 'property_type' }));
	const website_features_tags = website_features.map(tag => ({ tag, type: 'website_features' }));
	const third_party_features_tags = third_party_features.map(tag => ({ tag, type: 'third_party_features' }));
	const allTags = [...website_purpose_tags, ...property_type_tags, ...website_features_tags, ...third_party_features_tags];

	return (
		<Card to={`/site/${site_id}`} className='shadow-lg'>
			{!approved && (
				<Banner>
					<i className='fas fa-thumbs-up'></i> REVIEW
				</Banner>
			)}
			{approved && visibility?.includes('Internal Only') && (
				<Banner>
					<i className='fas fa-eye'></i> INTERNAL
				</Banner>
			)}
			{user && <FavoriteButton site={site} user={user} allExamples={allExamples} mutate={mutate} renderedExamples={renderedExamples} setRenderedExamples={setRenderedExamples} />}
			<Image src={thumbnail_url} alt={url} loading='lazy' />
			<Content>
				<Link to={`/site/${site.site_id}`} className='text-decoration-none text-white'>
					<Url>{url}</Url>
					<Name>{company_name}</Name>
				</Link>
				<TagRow>
					<i className='fas fa-tags'></i>
					<TagContainer>
						{allTags.map(tag => (
							<Tag key={tag.tag} className='text-decoration-none' $color={color_scheme[0]} to={`/examples?${new URLSearchParams({ [tag.type]: tag.tag }).toString()}`}>
								{tag.tag}
							</Tag>
						))}
					</TagContainer>
				</TagRow>
			</Content>
		</Card>
	);
};

export default ExampleCard;
