import Helmet from 'react-helmet';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
	const navigate = useNavigate();

	return (
		<>
			<Helmet>
				<title>404 | Page Not Found | Live AppFolio Website Examples</title>
			</Helmet>
			<div className='p-3 p-md-5 d-flex flex-wrap justify-content-center align-items-center text-danger'>
				<div className='text-center'>
					<h1 style={{ fontSize: '5rem' }}>
						<i className='fas fa-exclamation-circle me-2'></i> 404
					</h1>
					<p className='text-secondary my-4'>Sorry, this page could not be found.</p>
					<Button
						variant={'secondary'}
						onClick={() => {
							navigate('/');
						}}
						className='mx-auto'>
						Go Home <i className='fas fa-home'></i>
					</Button>
				</div>
			</div>
		</>
	);
}
