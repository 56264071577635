import { useRef } from 'react';
import Footer from './Footer';
import MessageBar from 'components/shared/MessageBar';
import { Routes, Navigate, Route } from 'react-router-dom';
import Examples from 'components/Examples/Examples';
import Site from 'components/Site/Site';
import Find from 'components/Find/Find';
import Add from 'components/Add/Add';
import NotFound from 'components/pages/NotFound';
import Review from 'components/Review/Review';
import { useLogin } from 'utils/UserContext';
import styled from 'styled-components';

const Component = styled.main`
	grid-area: main;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden auto;
`;

const Wrapper = styled.div`
	max-width: 100%;
	width: 1280px;
	margin: 0 auto;
	padding: 2rem;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

export default function Main() {
	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };

	const mainRef = useRef(null);

	return (
		<Component ref={mainRef}>
			<MessageBar />
			<Wrapper>
				<Routes>
					<Route index element={<Navigate to='examples' />} />
					<Route path='add' element={<Add />} />
					<Route path='examples' element={<Examples mainRef={mainRef} />} />
					<Route path='find' element={<Find />} />
					<Route path='review' element={isAdmin ? <Review /> : <Navigate to='/' />} />
					<Route path='site'>
						<Route path=':id' element={<Site />} />
					</Route>
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Wrapper>
			<Footer />
		</Component>
	);
}
