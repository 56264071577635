import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { Button, Form } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { tidyLongDate, tidyUserEmail } from 'utils/Tidy';
import { useNavigate, useParams } from 'react-router-dom';
import Browser from './Browser';
import { useLogin } from 'utils/UserContext';
import Confirmation from 'components/shared/Confirmation';
import Tags from './Tags';
import { buildFilters } from 'config/filters.js';
import { Helmet } from 'react-helmet';
import { useMessage } from 'utils/MessageContext';
import { fetcher } from 'utils/Fetch';

const MainWrapper = styled.div`
	max-width: 1280px;
	margin: 0 auto;
`;

const Content = styled.div`
	display: grid;
	gap: 1rem;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr minmax(auto, 500px);
	align-items: start;
	gap: 1rem;

	@media (max-width: 991px) {
		grid-template-columns: 100%;

		${Content} {
			order: 1;
		}
	}
`;

const EditButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem;
	transition: 0.2s;
	cursor: pointer;
	color: var(--bs-secondary);
	opacity: 0;
	visibility: hidden;
	transition: 0.2s;

	&:hover i,
	&:focus i {
		color: var(--bs-primary) !important;
	}
`;

const Card = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	padding: 1rem;
	position: relative;
	display: grid;
	gap: 0.5rem;

	&:hover ${EditButton}, &:focus ${EditButton} {
		opacity: 1;
		visibility: visible;
	}
`;

const Field = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const Name = styled.span`
	color: var(--bs-secondary);
	flex: 0 0 auto;
`;

const EditInput = styled(Form.Control)`
	box-shadow: none !important;
	border-radius: 0 !important;
	border: 0 !important;
	border-bottom: 1px dashed #ddd !important;
	padding: 0;
	margin: 0;
	outline: 0;
	height: auto;
`;

const Value = styled.span`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;
`;

const Review = styled(Card)`
	background: var(--bs-warning);
	color: white;
	text-align: center;
	box-shadow: inset 0 -3px 0 #fff6 !important;

	i {
		opacity: 0.6;
		margin-right: 0.5rem;
	}
`;

const Site = () => {
	// Initial state
	const initialEditState = { company_name: '', location_state: '', company_info: false, website_purpose: false, property_type: false, website_features: false, third_party_features: false, color_scheme: false, search_tags: false, visibility: false };

	// Component state
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [isEditing, setIsEditing] = useState(initialEditState);
	const [formResponse, setFormResponse] = useState({});
	const [filters, setFilters] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { user } = useLogin();
	const { setMessage } = useMessage();

	// Use SWR Hook
	const { data: site } = useSWR(`/api/v1/examples/${id}`);
	const { url, company_name, website_designer, first_published_date, thumbnail_url, product_tier, website_template, approved, date_added } = { ...site };

	useEffect(() => {
		const getFilters = async () => {
			const data = await buildFilters();
			setFilters(data);
		};
		getFilters();
	}, []);

	// Update form response on mount
	useEffect(() => {
		if (site) {
			setFormResponse(site);
		}
	}, [site]);

	// Handle edit button click
	const handleIsEditing = fieldName => {
		setIsEditing({ ...isEditing, [fieldName]: !isEditing[fieldName] });
	};

	// On form input change
	const handleChange = e => {
		// Destructure target
		const { type, value, name, checked } = e.target;

		if (type !== 'checkbox') {
			setFormResponse({ ...formResponse, [name]: value });
		} else {
			let checkArray = formResponse[name] || [];
			checkArray = checkArray.filter(check => check !== value);
			if (checked) {
				checkArray.push(value);
			}
			setFormResponse({ ...formResponse, [name]: checkArray });
		}
	};

	// Handle example save
	const handleSave = async () => {
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/examples/${id}`, { method: 'PUT', body: JSON.stringify(formResponse) });
			mutate(`/api/v1/examples/${id}`);
			setIsEditing(initialEditState);
			setMessage({
				variant: 'success',
				text: 'Changes have been saved.'
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error saving these changes.'
			});
		} finally {
			setTimeout(() => setMessage(null), 3000);
		}
		setIsLoading(false);
	};

	// Handle delete
	const handleDelete = async () => {
		try {
			await fetch(`/api/v1/examples/${id}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } });
			setTimeout(() => navigate('/examples'), 500);
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error deleting this example.'
			});
		}
	};

	// Handle cancel
	const handleCancel = () => {
		setFormResponse(site);
		setIsEditing(initialEditState);
	};

	// Handle approve
	const handleApprove = async () => {
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/examples/${id}`, { method: 'PUT', body: JSON.stringify({ approved: true }) });
			mutate(`/api/v1/examples/${id}`);
			setIsEditing(initialEditState);
			setMessage({
				variant: 'success',
				text: 'This site has been approved.'
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error approving this site.'
			});
		} finally {
			setTimeout(() => setMessage(null), 3000);
		}
		setIsLoading(false);
	};

	return !site || isLoading ? (
		<LoadingOverlay />
	) : (
		<>
			<Helmet>
				<title>{company_name} | Live AppFolio Website Examples</title>
			</Helmet>
			<MainWrapper>
				<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={'delete this example site?'} confirmFunction={handleDelete} confirmType={'danger'} />
				<PageHeader heading={url} subheading={company_name} />
				<Wrapper>
					<Content>
						{!approved && user.isAdmin && (
							<Review>
								<div>
									<i className='fas fa-exclamation-circle'></i> This site requires review & approval before appearing on the list of examples.
								</div>
							</Review>
						)}
						<Card>
							<h5 className='m-0'>Site Info</h5>
							<Field>
								<Name>Template Design: </Name>
								<Value>{website_template}</Value>
							</Field>
							<Field>
								<Name>Product Tier: </Name>
								<Value>{product_tier}</Value>
							</Field>
							<Field>
								<Name>Website Designer: </Name>
								<Value>{tidyUserEmail(website_designer)}</Value>
							</Field>
							<Field>
								<Name>First Published: </Name>
								<Value>{tidyLongDate(first_published_date)}</Value>
							</Field>
							<Field>
								<Name>Date Added: </Name>
								<Value>{tidyLongDate(date_added)}</Value>
							</Field>
						</Card>
						<Card>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('company_info')}>{!isEditing.company_info ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<h5 className='m-0'>Company Info</h5>
							<Field>
								<Name>URL: </Name>
								<Value>
									<a href={`https://${url}`} target='_blank' rel='noopener noreferrer'>
										{url}
									</a>
								</Value>
							</Field>
							<Field>
								<Name>Name: </Name>
								{!isEditing.company_info ? <Value>{formResponse.company_name}</Value> : <EditInput value={formResponse.company_name} name='company_name' onChange={e => handleChange(e)} />}
							</Field>
							<Field>
								<Name>Location State: </Name>
								{!isEditing.company_info ? (
									<Value>{formResponse.location_state}</Value>
								) : (
									<EditInput as='select' name='location_state' value={formResponse.location_state} onChange={e => handleChange(e)} required>
										{filters
											?.find(filter => filter.name === 'location_state')
											?.values.map(value => (
												<option key={value}>{value}</option>
											))}
									</EditInput>
								)}
							</Field>
						</Card>
						<Card>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('website_purpose')}>{!isEditing.website_purpose ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<h5 className='m-0'>Website Purpose</h5>
							<Tags name={'website_purpose'} tags={formResponse.website_purpose} isEditing={isEditing.website_purpose} handleChange={handleChange} />
						</Card>
						<Card>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('property_type')}>{!isEditing.property_type ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<h5 className='m-0'>Property Type</h5>
							<Tags name={'property_type'} tags={formResponse.property_type} isEditing={isEditing.property_type} handleChange={handleChange} />
						</Card>
						<Card>
							<h5 className='m-0'>Website Features</h5>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('website_features')}>{!isEditing.website_features ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<Tags name={'website_features'} tags={formResponse.website_features} isEditing={isEditing.website_features} handleChange={handleChange} />
						</Card>
						<Card>
							<h5 className='m-0'>Third-Party Features</h5>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('third_party_features')}>{!isEditing.third_party_features ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<Tags name={'third_party_features'} tags={formResponse.third_party_features} isEditing={isEditing.third_party_features} handleChange={handleChange} />
						</Card>
						<Card>
							<h5 className='m-0'>Color Scheme</h5>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('color_scheme')}>{!isEditing.color_scheme ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<Tags name={'color_scheme'} tags={formResponse.color_scheme} isEditing={isEditing.color_scheme} handleChange={handleChange} />
						</Card>
						<Card>
							<h5 className='m-0'>Search Tags</h5>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('search_tags')}>{!isEditing.search_tags ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<Tags name={'search_tags'} tags={formResponse.search_tags} isEditing={isEditing.search_tags} handleChange={handleChange} formResponse={formResponse} setFormResponse={setFormResponse} />
						</Card>
						<Card>
							<h5 className='m-0'>Visibility</h5>
							{user.isAdmin && <EditButton onClick={() => handleIsEditing('visibility')}>{!isEditing.visibility ? <i className='fas fa-edit'></i> : <i className='fas fa-check'></i>}</EditButton>}
							<Tags name={'visibility'} tags={formResponse.visibility} isEditing={isEditing.visibility} handleChange={handleChange} />
						</Card>
					</Content>
					<Browser url={url} image={thumbnail_url} />
				</Wrapper>
				{user.isAdmin && (
					<ButtonContainer>
						{!approved && user.isAdmin && (
							<Button variant='success' className='text-white m-1' onClick={() => handleApprove()}>
								Approve <i className='fas fa-check-circle'></i>
							</Button>
						)}
						{formResponse !== site && (
							<Button onClick={() => handleSave()} variant='primary' className='m-1'>
								Save <i className='fas fa-save'></i>
							</Button>
						)}
						{(Object.values(isEditing).some(value => value === true) || formResponse !== site) && (
							<Button onClick={() => handleCancel()} variant='secondary' className='m-1'>
								Cancel <i className='fas fa-backspace'></i>
							</Button>
						)}
						<Button onClick={() => setShowConfirmation(true)} variant='danger' className='m-1'>
							Delete <i className='fas fa-trash-alt'></i>
						</Button>
					</ButtonContainer>
				)}
			</MainWrapper>
		</>
	);
};

export default Site;
