import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useMessage } from 'utils/MessageContext';
import useSWR from 'swr';

const Component = styled.div`
	display: grid;
	gap: 0.75rem;
`;

const SearchRow = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	gap: 0.5rem;
`;

const AddSearch = styled.button`
	all: unset;
	background: var(--bs-primary);
	width: 2rem;
	height: 2rem;
	border-radius: 0.25rem;
	color: #fffa;
	display: grid;
	place-items: center;
	transition: 0.2s;

	&:hover,
	&:focus-visible {
		color: #fff;
	}
`;

const Tags = styled.div``;

const SearchTags = ({ formResponse, setFormResponse, handleChange, embed = false, ...props }) => {
	// Hooks
	const { setMessage } = useMessage();
	const { data: tags } = useSWR('/api/v1/examples/tags');
	const options = tags ? tags : [];

	// Refs
	const typeAheadRef = useRef();

	// Handlers
	const handleButtonClick = e => {
		e.preventDefault();
		const value = typeAheadRef.current.state.text;
		handleAddSearchTerm(value);
	};

	const handleSuggestionClick = value => {
		handleAddSearchTerm(value);
	};

	const handleAddSearchTerm = value => {
		if (value) {
			const newSearchArray = formResponse.search_tags;
			if (!newSearchArray.includes(value)) {
				newSearchArray.push(value);
				setFormResponse(prev => ({ ...prev, search_tags: newSearchArray }));
				typeAheadRef.current.clear();
			} else {
				setMessage({ variant: 'warning', text: `Search tag ${value} already exists.` });
			}
		}
	};

	return (
		<Component {...props}>
			{!embed && (
				<div>
					<Form.Label className='m-0'>Search Tags</Form.Label>
					<Form.Text className='d-block text-secondary'>Additional tags to help users find unique features about this website.</Form.Text>
				</div>
			)}
			<Form.Group className='m-0'>
				<SearchRow>
					<Typeahead id='search-tag-input' ref={typeAheadRef} options={options} minLength={3} onChange={([value]) => handleSuggestionClick(value)} $embed={embed} />
					<AddSearch onClick={e => handleButtonClick(e)}>
						<i className='fas fa-plus'></i>
					</AddSearch>
				</SearchRow>
			</Form.Group>
			<Tags>
				{formResponse?.search_tags?.map(value => (
					<Form.Check custom key={value} className='mb-1' type='checkbox' name='search_tags' label={value} value={value} id={value} checked={formResponse.search_tags.includes(value)} onChange={e => handleChange(e)} />
				))}
			</Tags>
		</Component>
	);
};

export default SearchTags;
