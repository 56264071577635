import { FormControl } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	overflow: hidden;
`;

const SearchInput = styled(FormControl)`
	border-radius: 0;
`;

const Heading = styled.h5`
	background: var(--bs-dark);
	color: white;
	padding: 0.75rem 1rem;
	margin: 0;

	i {
		font-size: 1rem;
	}
`;

const Wrapper = styled.div`
	display: flex;

	.form-control::-webkit-search-decoration,
	.form-control::-webkit-search-cancel-button,
	.form-control::-webkit-search-results-button,
	.form-control::-webkit-search-results-decoration {
		display: none;
	}
`;

const Submit = styled.button`
	all: unset;
	width: 2.5rem;
	display: grid;
	place-items: center;
	background: white;
	transition: 0.1s;

	&:hover,
	&:focus-visible {
		color: var(--bs-primary) !important;
	}
`;

const Search = ({ register, submit }) => {
	return (
		<Component>
			<Heading>
				<i className='fas fa-search text-secondary me-1'></i> Search
			</Heading>
			<Wrapper>
				<SearchInput type='search' {...register('search', { onSubmit: () => submit() })} />
				<Submit>
					<i className='fas fa-search'></i>
				</Submit>
			</Wrapper>
		</Component>
	);
};

export default Search;
