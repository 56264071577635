import { useAccordionButton } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled.button`
	all: unset;
`;

const Toggle = ({ eventKey, ...props }) => {
	// Handlers
	const handleClick = useAccordionButton(eventKey);

	return <Component type='button' onClick={handleClick} {...props} />;
};

export default Toggle;
