const getTemplateNames = async () => {
	const response = await fetch('/api/v1/templates');
	const data = await response.json();
	const names = data.filter(template => template.product_tier !== 'Legacy').map(template => template.name);
	return names;
};

export const buildFilters = async () => {
	const templateNames = await getTemplateNames();
	const filters = [
		{
			name: 'website_purpose',
			values: ['Company Site', 'Single Property', 'Community Association'],
			input_type: 'radio',
			show_in_sidebar: true,
			open_by_default: true
		},
		{
			name: 'property_type',
			values: ['Affordable Housing', 'Commercial', 'Co-working Space', 'Development', 'Land', 'Mixed Use', 'Mobile Homes', 'Real Estate', 'Residential', 'Retail', 'Senior Living', 'Storage', 'Student Housing', 'Vacation Rentals'],
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: true
		},
		{
			name: 'website_features',
			values: ['AppFolio Designed Logo', 'Before/After Slider', 'Blog', 'Collections Property Pages', 'Engrain Site Map', 'FAQs', 'Floor Plans', 'Investor Portal', 'Isolated Vacancy Feeds', 'LISA', 'Professional Photography', 'Team Information', 'Testimonials', 'Video Background'],
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: true
		},
		{
			name: 'third_party_features',
			values: ['Chat Bot', 'Event Calendar', 'IDX', 'Map Integration', 'Reservations', 'Social Media Feed', 'Virtual Tours'],
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: false
		},
		{
			name: 'website_template',
			values: templateNames,
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: false
		},
		{
			name: 'product_tier',
			values: ['S1', 'S2', 'SE', 'Custom'],
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: false
		},
		{
			name: 'color_scheme',
			values: ['Red', 'Orange', 'Gold', 'Yellow', 'Green', 'Cyan', 'Blue', 'Purple', 'Pink', 'Brown', 'Black', 'Grey', 'White'],
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: false
		},
		{
			name: 'location_state',
			values: ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
			input_type: 'checkbox',
			show_in_sidebar: true,
			open_by_default: false
		},
		{
			name: 'website_designer',
			values: [],
			input_type: 'checkbox',
			show_in_sidebar: false,
			open_by_default: false
		},
		{
			name: 'search_tags',
			values: [],
			input_type: 'checkbox',
			show_in_sidebar: false,
			open_by_default: false
		},
		{
			name: 'visibility',
			values: ['Internal Only'],
			input_type: 'checkbox',
			show_in_sidebar: false,
			open_by_default: false
		}
	];
	return filters;
};
