import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Tidy URLs
export const tidyUrl = url => {
	return url && url.replace('http://', '').replace('https://', '').replace(/\/$/g, '');
};

// Tidy dates
export const tidyDate = date => {
	return date && dayjs(date).format('MM/DD/YYYY');
};

export const tidyLongDate = date => {
	return date && dayjs(date).format('D MMMM YYYY');
};

export const tidyDatePicker = date => {
	return date && dayjs(date).format('YYYY-MM-DD');
};

export const tidyUTCDate = date => {
	return date && dayjs(date).utc().format('MM/DD/YYYY');
};

export const tidyTime = date => {
	return date && dayjs(date).format('h:mm A');
};

export const tidyDateTime = date => {
	return date && dayjs(date).format('MMMM D YYYY @ h:mm A');
};

// Tidy text
export const tidyCapitalize = text => {
	return text
		.toLowerCase()
		.split(/ |_/)
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
};

export const tidyUnderscores = text => {
	return text.toLowerCase()[0].toUpperCase() + text.slice(1).split('_').join(' ');
};

export const addUnderscores = text => {
	return text
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g, ' ')
		.replace(/\s\s+/g, ' ')
		.split(' ')
		.join('_');
};

export const addDashes = text => {
	return text
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g, ' ')
		.replace(/\s\s+/g, ' ')
		.split(' ')
		.join('-');
};

export const tidyDashes = text => {
	const withSpaces = text.toLowerCase().split('-').join(' ');
	return withSpaces[0].toUpperCase() + withSpaces.slice(1);
};

export const tidyParagraphs = text => {
	return text.split('\n').map(paragraph => `<p>${paragraph}</p>`);
};

// Tidy email addresses
export const tidyUserEmail = email => {
	return email
		.split('@')[0]
		.replace('.', ' ')
		.split(' ')
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
};

// Tidy database strings
export const tidyDatabase = url => {
	const urlObject = new URL(url);
	if (urlObject.hostname === 'api.appfoliotest.com') {
		const db = urlObject.pathname.replace('/api/v1/listings/', '');
		return `${db}.appfolio.com`;
	} else {
		return urlObject.hostname;
	}
};

export const tidyPropertyGroup = url => {
	return new URL(url).searchParams.get('property_lists[]');
};

// Tidy file sizes
export const tidyFileSize = bytes => {
	const kilo = 1024;
	const sizes = ['Bytes', 'KB', 'MB'];
	const i = Math.floor(Math.log(bytes) / Math.log(kilo));
	return `${parseFloat((bytes / Math.pow(kilo, i)).toFixed(2))} ${sizes[i]}`;
};

export const transformURLParamsToObject = params => {
	const filters = ['property_type', 'website_features', 'third_party_features', 'website_template', 'product_tier', 'color_scheme', 'location_state'];
	const paramsObject = {};
	for (const [key, value] of params.entries()) {
		filters.includes(key) ? (paramsObject[key] = value.split(',')) : (paramsObject[key] = value);
	}
	return paramsObject;
};
