import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { buildFilters } from 'config/filters.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMessage } from 'utils/MessageContext';
import LoadingButton from 'components/shared/LoadingButton';
import { fetcher } from 'utils/Fetch';
import SearchTags from 'components/shared/SearchTags';

const CustomForm = styled(Form)`
	max-width: 1280px;
	margin: 0 auto;
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(3, 1fr);
	align-items: start;

	@media (max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 1rem;
	}

	.form-label {
		font-weight: bold;
	}
`;

const CheckWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const ColorSwatch = styled.div`
	width: 1rem;
	height: 1rem;
	background: ${({ $color }) => `var(--${$color})`};
	border-radius: 50%;
	box-shadow: inset 0 -2px 0 #0001;
`;

const Required = styled.span`
	color: var(--bs-danger);
`;

const SubmitWrapper = styled.div`
	display: grid;
	gap: 1.5rem;
	justify-items: start;
	align-items: start;
`;

const Add = () => {
	// State
	const initialFormState = {
		site_id: '',
		company_name: '',
		location_state: '',
		website_designer: '',
		website_purpose: [],
		property_type: [],
		website_features: [],
		third_party_features: [],
		color_scheme: [],
		search_tags: [],
		visibility: []
	};
	const [formResponse, setFormResponse] = useState(initialFormState);
	const [loading, setLoading] = useState(false);
	const [filters, setFilters] = useState([]);

	// Hooks
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { setMessage } = useMessage();

	// Effects
	useEffect(() => {
		const getFilters = async () => {
			const data = await buildFilters();
			setFilters(data);
		};
		getFilters();
	}, []);

	useEffect(() => {
		if (searchParams) {
			for (const [key, value] of searchParams.entries()) {
				setFormResponse(prev => ({ ...prev, [key]: value }));
			}
			setSearchParams('');
		}
	}, [searchParams, setSearchParams]);

	// Handlers
	const handleSubmit = async e => {
		// Prevent page refresh
		e.preventDefault();

		// Manual validation
		const manualFields = [
			{ key: 'website_purpose', name: 'Website Purpose' },
			{ key: 'property_type', name: 'Property Type' },
			{ key: 'color_scheme', name: 'Color Scheme' }
		];
		const missingFields = manualFields.reduce((total, current) => (formResponse[current.key].length === 0 ? [...total, current.name] : total), []);

		// Property type validation
		if (missingFields.length > 0) {
			setMessage({
				variant: 'warning',
				text: `Please select at least one option for the following fields: ${missingFields.join(', ')}`
			});
		} else {
			setLoading(true);
			// Send form response
			try {
				await fetcher(`/api/v1/examples/${formResponse.site_id}`, {
					method: 'POST',
					body: JSON.stringify(formResponse)
				});
				setLoading(false);
				navigate(`/site/${formResponse.site_id}`);
			} catch (error) {
				setLoading(false);
				setMessage({
					variant: 'danger',
					text: error.message
				});
			}
		}
	};

	const handleChange = e => {
		// Destructure target
		const { type, value, name, checked } = e.target;

		if (type !== 'checkbox') {
			setFormResponse({ ...formResponse, [name]: value });
		} else {
			let checkArray = formResponse[name] || [];
			checkArray = checkArray.filter(check => check !== value);
			if (checked) {
				checkArray.push(value);
			}
			setFormResponse({ ...formResponse, [name]: checkArray });
		}
	};

	return (
		<>
			<Helmet>
				<title>Add a Site | Live AppFolio Website Examples</title>
			</Helmet>
			<PageHeader heading={'Add a Site'} subheading={'Add a new site to the list of examples.'} />
			<CustomForm onSubmit={e => handleSubmit(e)}>
				<FormGroup controlId='site_id'>
					<FormLabel>
						Site ID <Required>*</Required>
					</FormLabel>
					<FormControl type='input' name='site_id' value={formResponse.site_id} onChange={e => handleChange(e)} required></FormControl>
				</FormGroup>
				<FormGroup controlId='company_name'>
					<FormLabel>
						Company Name <Required>*</Required>
					</FormLabel>
					<FormControl type='input' name='company_name' value={formResponse.company_name} onChange={e => handleChange(e)} required></FormControl>
				</FormGroup>
				<FormGroup controlId='location_state'>
					<FormLabel>
						Location State <Required>*</Required>
					</FormLabel>
					<Form.Select name='location_state' value={formResponse.location_state} onChange={e => handleChange(e)} required>
						<option></option>
						{filters
							?.find(filter => filter.name === 'location_state')
							?.values.map(value => (
								<option key={value}>{value}</option>
							))}
					</Form.Select>
				</FormGroup>
				<FormGroup controlId='website_purpose'>
					<FormLabel>
						Website Purpose <Required>*</Required>
					</FormLabel>
					{filters
						?.find(filter => filter.name === 'website_purpose')
						?.values.map(value => (
							<Form.Check key={value} className='mb-1' type='checkbox' name='website_purpose' label={value} value={value} id={value} onChange={e => handleChange(e)} />
						))}
				</FormGroup>
				<FormGroup controlId='property_type'>
					<FormLabel>
						Property Type <Required>*</Required>
					</FormLabel>
					{filters
						?.find(filter => filter.name === 'property_type')
						?.values.map(value => (
							<Form.Check key={value} className='mb-1' type='checkbox' name='property_type' label={value} value={value} id={value} onChange={e => handleChange(e)} />
						))}
				</FormGroup>
				<FormGroup controlId='color_scheme'>
					<FormLabel>
						Color Scheme <Required>*</Required>
					</FormLabel>
					{filters
						?.find(filter => filter.name === 'color_scheme')
						?.values.map(value => (
							<CheckWrapper key={value} className='mb-1'>
								<Form.Check type={'checkbox'} id={value} label={value} value={value} name='color_scheme' onChange={e => handleChange(e)} />
								<ColorSwatch $color={value} />
							</CheckWrapper>
						))}
				</FormGroup>
				<FormGroup controlId='website_features'>
					<FormLabel>Website Features</FormLabel>
					{filters
						?.find(filter => filter.name === 'website_features')
						?.values.map(value => (
							<Form.Check key={value} className='mb-1' type='checkbox' name='website_features' label={value} value={value} id={value} onChange={e => handleChange(e)} />
						))}
				</FormGroup>
				<FormGroup controlId='third_party_features'>
					<FormLabel>Third-Party Features</FormLabel>
					{filters
						?.find(filter => filter.name === 'third_party_features')
						?.values.map(value => (
							<Form.Check key={value} className='mb-1' type='checkbox' name='third_party_features' label={value} value={value} id={value} onChange={e => handleChange(e)} />
						))}
				</FormGroup>
				<SubmitWrapper>
					<SearchTags formResponse={formResponse} setFormResponse={setFormResponse} handleChange={handleChange} />
					<FormGroup controlId='visibility'>
						<FormLabel>Visibility</FormLabel>
						<Form.Check type='switch' label='Internal Only' id='visibility-switch' value='Internal Only' name='visibility' onChange={e => handleChange(e)} />
						<Form.Text className='text-secondary'>Visible only to Websites Team members?</Form.Text>
					</FormGroup>
					{!loading ? (
						<Button type='submit'>
							Add site <i className='fas fa-angle-right'></i>
						</Button>
					) : (
						<LoadingButton />
					)}
				</SubmitWrapper>
			</CustomForm>
		</>
	);
};

export default Add;
