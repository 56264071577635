import { FormCheck, Accordion, AccordionCollapse } from 'react-bootstrap';
import styled from 'styled-components';
import { addDashes, tidyCapitalize } from 'utils/Tidy';
import Toggle from './Toggle';

const Component = styled.div`
	border-bottom: 1px solid #eee;
	padding: 0.75rem 0;

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		border: 0;
		padding-bottom: 0;
	}
`;

const Collapse = styled.div`
	position: relative;
	margin-top: 0.5rem;
`;

const CheckWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding-left: 3px;
`;

const ColorSwatch = styled.div`
	width: 1rem;
	height: 1rem;
	background: ${({ $color }) => `var(--${$color})`};
	border-radius: 50%;
	box-shadow: inset 0 -2px 0 #0001;
`;

const Category = ({ category, register, submit }) => {
	// State
	const { name, open_by_default, values } = { ...category };

	// Remove underscores & capitalize
	const categoryName = tidyCapitalize(name);

	return (
		<Accordion as={Component} defaultActiveKey={open_by_default && name}>
			<Toggle eventKey={name}>
				<h6 className='m-0'>
					{categoryName} <i className='fas fa-angle-down text-secondary ms-1'></i>
				</h6>
			</Toggle>
			<AccordionCollapse eventKey={name}>
				<Collapse>
					{values.map(value => (
						<CheckWrapper key={value} className='mb-1'>
							<FormCheck type={name === 'website_purpose' ? 'radio' : 'checkbox'} id={addDashes(value)} label={value} {...register(name, { onChange: () => submit() })} value={value} />
							{name === 'color_scheme' && <ColorSwatch $color={value} />}
						</CheckWrapper>
					))}
				</Collapse>
			</AccordionCollapse>
		</Accordion>
	);
};

export default Category;
