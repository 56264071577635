import { useState } from 'react';
import ExampleCard from 'components/Examples/Main/ExampleCard';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';

const Component = styled.div``;

const NoResults = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	i {
		font-size: 5rem;
		opacity: 0.25;
	}
`;

const Chunk = styled.section`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-auto-rows: max-content;
	gap: 1.5rem;
	margin-bottom: 1rem;

	@media (max-width: 768px) {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		gap: 1.5rem;
	}
`;

const ExamplesContainer = ({ allExamples, user, mutate, renderedExamples, setRenderedExamples }) => {
	const [currentChunk, setCurrentChunk] = useState(1);

	// Handle scroll change
	const handleChange = (i, inView) => {
		if (inView & (i >= currentChunk)) setCurrentChunk(i + 1);
	};

	// Chunk array into 16 items
	const chunks = renderedExamples.reduce((total, current, index) => {
		index % 16 ? total[total.length - 1].push(current) : total.push([current]);
		return total;
	}, []);

	return renderedExamples.length > 0 ? (
		<Component>
			{chunks.slice(0, currentChunk).map((chunk, i) => (
				<InView as={Chunk} key={i} onChange={inView => handleChange(i + 1, inView)}>
					{chunk.map(example => (
						<ExampleCard key={example._id} site={example} user={user} allExamples={allExamples} mutate={mutate} renderedExamples={renderedExamples} setRenderedExamples={setRenderedExamples} />
					))}
				</InView>
			))}
		</Component>
	) : (
		<NoResults>
			<i className='fas fa-sad-cry'></i>
			<h5 className='my-3 text-center'>No examples found.</h5>
		</NoResults>
	);
};

export default ExamplesContainer;
